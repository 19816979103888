import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import './App.css';

import authProvider from './AuthProvider';
import { dataProvider } from './Consts';
import { UserCreate, UserEdit, UserList } from './models/user';
import UserIcon from '@mui/icons-material/Group';
import AuditorIcon from '@mui/icons-material/WorkRounded';
import CustomerIcon from '@mui/icons-material/EventBusyRounded';
import StoreIcon from '@mui/icons-material/Store';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TimesheetIcon from '@mui/icons-material/Timelapse';
import SoftwareIcon from '@mui/icons-material/Computer';
import { AuditorCreate, AuditorEdit, AuditorList, AuditorShow } from './models/auditors';
import { CustomerCreate, CustomerEdit, CustomerList, CustomerShow } from './models/customers';
import { StoreCreate, StoreEdit, StoreList, StoreShow } from './models/stores';
import { EventCreate, EventEdit, EventList, EventShow } from './models/events';
import { ScheduleList, ScheduleEdit, ScheduleCreate, ScheduleShow } from './models/schedules';
import { SoftwareCreate, SoftwareEdit, SoftwareList, SoftwareShow } from './models/software';
import { TimesheetList } from './models/timesheet';

function App() {
  return (
    <Admin dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={UserIcon} />
      <Resource name="auditors" list={AuditorList} edit={AuditorEdit} create={AuditorCreate} show={AuditorShow} icon={AuditorIcon} />
      <Resource name="customers" list={CustomerList} edit={CustomerEdit} create={CustomerCreate} show={CustomerShow} icon={CustomerIcon} />
      <Resource name="stores" list={StoreList} edit={StoreEdit} create={StoreCreate} show={StoreShow} icon={StoreIcon} />
      <Resource name="events" list={EventList} edit={EventEdit} create={EventCreate} show={EventShow} icon={EventIcon} />
      <Resource name="schedules" list={ScheduleList} edit={ScheduleEdit} create={ScheduleCreate} show={ScheduleShow} icon={ScheduleIcon} />
      <Resource name="timesheet" list={TimesheetList} icon={TimesheetIcon} />
      <Resource name="software" list={SoftwareList} edit={SoftwareEdit} create={SoftwareCreate} show={SoftwareShow} icon={SoftwareIcon} />
    </Admin>
  );
}

export default App;
