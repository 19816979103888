import { List, Datagrid, TextField, SelectInput } from "react-admin";

const MonthChoices = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
];

// todo: generate these fields automatically, eg. latest 6 years!
const YearChoices = [
    { id: 2020, name: '2020' },
    { id: 2021, name: '2021' },
    { id: 2022, name: '2022' },
    { id: 2023, name: '2023' },
    { id: 2024, name: '2024' },
    { id: 2025, name: '2025' },
];

const timesheetFilters = [
    // <SearchInput source="q" alwaysOn />,
    <SelectInput 
        source="ccustom_year" 
        choices={YearChoices} 
        label="Year" 
        alwaysOn 
    />,
    <SelectInput 
        source="ccustom_month" 
        choices={MonthChoices} 
        label="Month" 
        alwaysOn 
    />
];

// todo: 
//  - add non-inventory and coount/inventory hours
//  - remove id
export const TimesheetList = (props: any) => (
    <List {...props} filters={timesheetFilters} >
        <Datagrid>
            <TextField source="id" />
            <TextField source="auditor_id" />
            <TextField source="tot_hours" />
        </Datagrid>
    </List>
);
