import { List, Datagrid, TextField, FunctionField, EditButton, Edit, SimpleForm, TextInput, Create, SearchInput, 
    DateField, Show, SimpleShowLayout, RichTextField, 
    ShowButton, ReferenceInput, SelectInput, DateTimeInput, FileInput, FileField, ReferenceField, 
    ReferenceArrayInput, AutocompleteArrayInput, ReferenceArrayField, 
    AutocompleteInput, BooleanInput, LinearProgress,
    Link} from "react-admin";
import { Box, Typography } from '@mui/material';
import { IsActiveIcon } from "../Consts";

const Progress = ({ progress }: { progress: number }) => {
    return (
        <Box display="flex" alignItems="center" width="100%">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">
                    {`${Math.round(progress )}%`}
                </Typography>
            </Box>
        </Box>
    );
};

// status helpers
const statusChoices = [
    { id: 0, name: 'SCHEDULED' },
    { id: 1, name: 'ACTIVE' },
    { id: 2, name: 'COMPLETED' },
    { id: 3, name: 'ARCHIVED' },
];

const StatusField = (props: any) => {
    return (
        <FunctionField
            {...props}
            render={(record: any) => {
                const status = statusChoices.find(choice => choice.id === record.status);
                return status ? status.name : 'Unknown';
            }}
        />
    );
};

const eventFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput 
        source="status" 
        choices={statusChoices} 
        label="Status" 
        // defaultValue={1}
        alwaysOn 
    />
];

// detect using: ListGuesser
export const EventList = (props: any) => (
    <List {...props} filters={eventFilters} >
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="store_id" reference="stores">
                <TextField source="name" />
            </ReferenceField>
            <StatusField source="status" />
            <FunctionField
                label="Pin"
                render={(record: any) => (
                    <Link to={`/schedules?filter=${encodeURIComponent(JSON.stringify({ pin: record.pin }))}`}>
                        {record.pin}
                    </Link>
                )}
            />
            <TextField source="pc_operator_badge" />
            <FunctionField
                label="Progress"
                render={(record: any) => (
                    <Progress progress={record.progress || 0} />
                )}
            />
            <TextField source="schedule_time" />
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);

const optionRenderer = (choice: { id: any; firstname: any; lastname: any; }) => 
        `${choice.id} - ${choice.firstname} ${choice.lastname}`;

// detect using: EditGuesser
export const EventEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput source="store_id" reference="stores">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput source="auditors" reference="auditors">
                <AutocompleteArrayInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <FileInput source="attachments_info" label="Anagraphic attachment" multiple>
                <FileField source="src" title="title" />
            </FileInput>
            <SelectInput source="status" choices={statusChoices} defaultValue={0} />
            <TextInput source="pin" />
            <TextInput source="auth" />
            <ReferenceArrayInput source="pc_operator_badge" reference="auditors">
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <DateTimeInput source="schedule_time" />
            <BooleanInput source="expire_date_enabled" label="Expire date enabled?" />
            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export const EventCreate = (props: any) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <ReferenceInput source="store_id" reference="stores">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceArrayInput source="auditors" reference="auditors">
                <AutocompleteArrayInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <FileInput source="attachments_info" label="Anagraphic attachment" multiple>
                <FileField source="src" title="title" />
            </FileInput>
            <SelectInput source="status" choices={statusChoices}  defaultValue={0} />
            <TextInput source="pin" />
            <TextInput source="auth" />
            <ReferenceArrayInput source="pc_operator_badge" reference="auditors">
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceArrayInput>
            <DateTimeInput source="schedule_time" />
            <BooleanInput source="expire_date_enabled" label="Expire date enabled?" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export const EventShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField source="store_id" reference="stores">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="auditors" reference="auditors" />
            <FileField source="attachments_info[0].src" 
                    title="attachments_info[0].title" label="Anagraphic" download="true" />
            <FileField source="link.url" label="Closeout archive" title="link.title" download="true" />
            <StatusField source="status" />
            <TextField source="pin" />
            <TextField source="auth" />
            <FunctionField
                source="progress"
                render={(record: any) => `${record.progress || 0}%`}
                label="Progress"
            />
            <TextField source="pc_operator_badge" />
            <DateField source="schedule_time" />
            <DateField source="start_time" />
            <DateField source="end_time" />
            <FunctionField
                label="Expire date enabled?"
                render={(record: any) => <IsActiveIcon isActive={record.expire_date_enabled} />}
            />
            <RichTextField source="description" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);
